import { Component, OnInit } from '@angular/core';
import { SidebarFiltersService } from 'core/services/sidebar-filters/sidebar-filters.service';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

import { Company } from '../../core/models/entities/company/company';
import { User } from '../../core/models/entities/user/user';
import { AuthService } from '../../core/services/auth/auth.service';
import { CompanyService } from '../../core/services/company/company.service';
import { EgtecService } from '../../core/services/egtec/egtec.service';
import { LoadingService } from '../../core/services/loading/loading.service';
import { NfeService } from '../../core/services/nfe/nfe.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  pageTitle = 'Cadastro de Empresas';
  loggedUser: User;
  dataSource: any;
  count = 0;
  licencas = {
    licencasAdquiridas: 0,
    licencasDisponiveis: 0
  };

  infoModalLog: string;
  listLogModal: any;
  reasonsModalAtivate: any;
  modalTitle: string;
  textInfo: string;
  showModalDownload: boolean;
  showModalLog: boolean;
  showModalDelete: boolean;
  showModalRegister: boolean;
  showModalChangeStatus: boolean;
  selectedCompany: Company | any;
  softwaresPdv = [];
  filterStatusCompany = [
    { text: 'Ativo', value: true },
    { text: 'Inativo', value: false }
  ];
  filterCorporativo = [
    { text: 'SIM', value: true },
    { text: 'NÃO', value: false }
  ];

  columnnEditorOptions: any = {
    mask: '00.000.000/0000-00'
  };
  filterVisible: boolean;
  filterValues: any;

  constructor(
    private companyService: CompanyService,
    private nfeService: NfeService,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private authService: AuthService,
    private egtecService: EgtecService,
    private sidebarFiltersService: SidebarFiltersService
  ) {}

  async ngOnInit() {
    this.getLoggedUser();
    this.loadingService.open();
    await this.getLicencas();
    if (!this.loggedUser.indAdministradorSistema) {
      await this.getCompanies();
    }
    void this.getSoftaresPdv();
    this.loadingService.close();
    this.listenSidebarFilterActions();
  }

  clearDatasource() {
    this.dataSource = [];
  }

  getLoggedUser() {
    this.loggedUser = this.authService.getUserValue();
  }

  async getCompanies() {
    var filtroPorMaster = '';
    var filtroPorCNPJ = '';

    if (this.loggedUser.indAdministradorSistema) {
      filtroPorMaster =
        this.filterValues.idUsuariosMaster != null
          ? this.filterValues.idUsuariosMaster.join(',')
          : '';
      filtroPorCNPJ =
        this.filterValues.cnpjEmpresas != null
          ? this.filterValues.cnpjEmpresas?.replace(/[^\d]+/g, '')
          : '';

      if (
        this.filterValues.cnpjEmpresas != undefined &&
        this.filterValues.cnpjEmpresas != '' &&
        filtroPorCNPJ == ''
      ) {
        // O usuário preencheu o campo de CNPJ, mas preencheu apenas com letras, pois após a limpeza do valor enviado, a variável ficou vazia
        this.messageService.add({
          severity: 'warn',
          summary: 'Alerta!',
          key: 'global-toast',
          sticky: true,
          detail: 'CNPJ Inválido. Por favor verifique o CNPJ informado.'
        });
        this.loadingService.close();
        return false;
      }
    }

    const response = await lastValueFrom(
      this.loggedUser.indAdministradorSistema
        ? this.companyService.listByUsersCnpjs(
            true,
            filtroPorMaster,
            filtroPorCNPJ
          )
        : this.companyService.list(true)
    );
    this.dataSource = response.dados.map(empresa => {
      this.count++;
      let responsavel;
      if (empresa?.contatos?.length) {
        responsavel = empresa?.contatos?.find(contato => {
          return contato?.indResponsavelEmpresa === true;
        });
      }
      const cnpjMask = empresa.cnpj;
      return {
        responsavel: responsavel,
        count: this.count,
        cnpjMask,
        ...empresa
      };
    });
  }

  async getLicencas() {
    const response = await lastValueFrom(this.authService.getLicencas());
    if (response?.dados) {
      this.licencas = response.dados;
    }
  }

  async getSoftaresPdv() {
    const response = await lastValueFrom(this.egtecService.listSoftwares());
    if (response?.dados) {
      this.softwaresPdv = response?.dados.map(software => {
        return { id: software.id, descricao: software.descricao };
      });
    }
  }

  addRowClass({ rowElement, data }) {
    if (data?.indCadastroIncompleto) {
      rowElement.classList.add('row-nfe-canceled');
    }
  }

  async register(company) {
    const { id: _, softwarePdv, responsavel, ...rest } = company;
    const contatos = [{ ...responsavel, indResponsavelEmpresa: true }];

    const newCompany = {
      softwarePdv: { id: softwarePdv?.id, descricao: softwarePdv?.descricao },
      ...rest,
      contatos
    };

    const response = await lastValueFrom(
      this.companyService.register(newCompany)
    );

    if (response?.dados) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        key: 'global-toast',
        sticky: true,
        detail: response?.dados?.mensagem
      });
      this.showModalRegister = false;
      if (!this.loggedUser.indAdministradorSistema) {
        this.loadingService.open();
        await this.getCompanies();
        await this.getLicencas();
        this.loadingService.close();
      }
    }
  }

  async update(company) {
    const { softwarePdv, responsavel, contatos, ...rest } = company;
    const contatoResponsavelIndex = contatos?.findIndex(contato => {
      return contato?.indResponsavelEmpresa === true;
    });
    const editedContato = { ...responsavel, indResponsavelEmpresa: true };

    if (contatoResponsavelIndex !== -1) {
      contatos[contatoResponsavelIndex] = {
        ...contatos[contatoResponsavelIndex],
        ...editedContato
      };
    } else {
      contatos.push(editedContato);
    }

    const modifiedCompany = {
      softwarePdv: { id: softwarePdv?.id, descricao: softwarePdv?.descricao },
      contatos,
      ...rest
    };

    const response = await lastValueFrom(
      this.companyService.update(modifiedCompany)
    );
    if (response?.dados) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso!',
        key: 'global-toast',
        sticky: true,
        detail: response?.dados?.mensagem
      });
      this.showModalRegister = false;
      this.loadingService.open();
      await this.getCompanies();
      this.loadingService.close();
    }
  }

  openModalDelete(row: Company) {
    this.selectedCompany = row;

    this.showModalDelete = true;
  }

  openModalRegister() {
    this.selectedCompany = undefined;
    if (
      this.licencas?.licencasDisponiveis > 0 ||
      this.loggedUser.indAdministradorSistema
    ) {
      this.showModalRegister = true;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção',
        key: 'global-toast',
        sticky: true,
        detail: 'Não há licenças disponíveis na sua conta'
      });
    }
  }

  openModalDownload(row: Company) {
    this.selectedCompany = row;
    this.showModalDownload = true;
  }

  close() {
    this.showModalDownload = false;
    this.selectedCompany = undefined;
  }

  openModalEdit(row: Company) {
    this.selectedCompany = row;
    this.showModalRegister = true;
  }

  listenModalDelete(response) {
    if (response?.mensagem) {
      this.messageService.add({
        severity: 'success',
        summary: 'Sucesso',
        key: 'global-toast',
        sticky: true,
        detail: response?.mensagem
      });
      this.getLicencas();
      this.getCompanies();
      this.showModalDelete = false;
      this.selectedCompany = undefined
    }

    if (response.hasOwnProperty('open')) {
      this.showModalDelete = response.open;
      this.selectedCompany = undefined
    }
  }

  async listenModalRegister(response) {
    if (response?.form) {
      if (response?.form?.id) {
        await this.update(response.form);
      } else {
        await this.register(response.form);
      }
    }

    if (response?.close) {
      this.showModalRegister = false;
    }
    this.selectedCompany = undefined;
  }

  async listenModalEnableDisable({ formData, close }) {
    if (formData) {
      let response = await lastValueFrom(this.companyService.enable(formData));
      if (response?.mensagem) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso',
          key: 'global-toast',
          sticky: true,
          detail: response?.mensagem
        });
      }

      this.getLicencas();
      this.getCompanies();
    }
    this.showModalChangeStatus = !close;
    this.selectedCompany = undefined as any;
  }

  async download() {
    const response = await lastValueFrom(this.companyService.download());
    var link = document.createElement('a');
    if (!response?.error) {
      link.href = window.URL.createObjectURL(response);
      link.download = 'empresas.xlsx';
      link.click();
    }
  }

  async openModalChangeStatus(row: Company) {
    const getReasons = await lastValueFrom(
      this.companyService.listdesableReasons()
    );
    if (getReasons?.dados) {
      this.reasonsModalAtivate = getReasons.dados;
    }
    this.selectedCompany = row;
    this.modalTitle = 'Ativação/Inativação de Empresas';
    this.textInfo = `Empresa: ${this.selectedCompany.nomeFantasia}\nInforme abaixo o motivo da ativação/inativação da empresa.`;
    this.showModalChangeStatus = true;
  }

  async openModalLog(row: Company) {
    this.selectedCompany = row;
    this.companyService.getLog(this.selectedCompany?.id).subscribe(listLog => {
      this.listLogModal = listLog;
    });
    this.infoModalLog = `${this.selectedCompany.razaoSocial}`;
    this.showModalLog = true;
  }

  cnpjTransform(cell) {
    return cell.cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }

  downloadBackupNfes(company: Company) {
    this.companyService.downloadBackupNfes([company.id]).subscribe(res => {
      if (res) {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          key: 'global-toast',
          sticky: true,
          detail: res?.dados?.mensagem
        });
        this.close();
      }
    });
  }

  toggleSidebarFilter() {
    this.filterVisible = !this.filterVisible;
  }

  listenSidebar(event) {
    this.filterVisible = event.visible;
  }

  async listenFilterValues(filters: any) {
    this.filterValues = filters;
    console.log(this.filterValues);
    this.loadingService.open();
    this.dataSource = [];
    await this.getCompanies();
    this.sidebarFiltersService.close();
    this.loadingService.close();
  }

  send = () => this.sidebarFiltersService.send();

  reset = () => this.sidebarFiltersService.reset();

  listenSidebarFilterActions() {
    this.sidebarFiltersService.receive().subscribe(action => {
      if (action?.close) {
        this.filterVisible = false;
      }
    });
  }

  listenModalLog(close) {
    if (close) {
      this.showModalLog = false;
      this.selectedCompany = undefined as any;
    }
  }
}
