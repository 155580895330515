<app-modal
  modalTitle="Validar Certificado"
  width="50vw"
  (closeModal)="close()"
  [removeTitleMargin]="false"
  [display]="open">
  <section class="modal-validate-certificate-container" [formGroup]="form">
    <section class="container-info-empresa mb-4">
      <span class="material-icons-outlined company-icon">domain</span>
      <section class="flex flex-column justify-content-center">
        <div>
          <span class="font-bold">Empresa:</span>
          {{ company?.nomeFantasia }}
        </div>
        <div>
          <span class="font-bold">CNPJ:</span>
          {{ company?.cnpj | mask: '00.000.000/0000-00' }}
        </div>
      </section>
    </section>
    <span class="font-bold label">Arquivo do Certificado</span>
    <section class="container-custom-upload mt-1 mb-3">
      <p-fileUpload
        #fileUpload
        accept=".pfx,.p12"
        [customUpload]="true"
        name="certificate[]"
        chooseLabel="Selecione o arquivo"
        chooseIcon="pi pi-cloud-upload"
        (onSelect)="onUploaded($event)"
        [showCancelButton]="false"
        [showUploadButton]="false">
        <ng-template let-file pTemplate="file"></ng-template>
        <ng-template pTemplate="content" let-files>
          <li *ngFor="let file of files">
            <span class="font-bold">{{ file.name }}</span>
            -
            <span>{{ file.size }} bytes</span>
          </li>
        </ng-template>
      </p-fileUpload>
    </section>

    <section class="container-password-certificate mt-1 mb-3">
      <label for="password-certificate" class="mb-1">
        Senha do Certificado
      </label>
      <div class="input-button-group">
        <input
          formControlName="senha"
          id="password-certificate"
          type="password"
          autocomplete="off"
          readonly
          onfocus="this.removeAttribute('readonly');"
          pInputText />
        <button
          [disabled]="form.invalid"
          pButton
          (click)="validate()"
          title="Validar"
          class="btn-green mr-2"
          type="button"
          label="Validar"
          icon="pi pi-check"></button>
      </div>
    </section>

    <section class="mt-1 mb-3">
      <div
        class="container-info warning text-red-message informacao-certificado">
        <span *ngIf="company?.idUsuarioAceiteTermo === 0">
          Informamos que o ato de assinar o termo de uso implica na declaração
          de que o assinante é responsável legal ou possui autorização expressa
          com poderes administrativos para representar a entidade ou organização
          mencionada. Ao assinar o termo de uso, o assinante concorda com todas
          as cláusulas e condições estabelecidas, assumindo a responsabilidade
          pelas ações e decisões relacionadas ao uso dos serviços oferecidos.
          Caso haja alguma dúvida ou necessidade de esclarecimento, recomendamos
          entrar em contato com o setor responsável antes de proceder com a
          assinatura.
        </span>
      </div>
    </section>
    <section class="mt-1 mb-3">
      <p-checkbox
        [binary]="true"
        formControlName="aceiteTermo"
        id="aceite-termo"
        [disabled]="!this.loggedUser.indAdministradorEmpresas"
        *ngIf="company?.idUsuarioAceiteTermo === 0"></p-checkbox>
      <label
        *ngIf="company?.idUsuarioAceiteTermo === 0"
        id="info-aceite-termo"
        for="aceite-termo">
        Estou ciente e concordo com o
        <a href="#" (click)="loadContrato($event)">termo de adesão</a>
        do CONFEAR
      </label>
      <label
        *ngIf="company?.idUsuarioAceiteTermo !== 0"
        id="info-termo-aceite"
        for="aceite-termo">
        <span style="color: #3b89be">
          Termo Aceito em
          {{ company?.dataAceiteTermo | date: 'dd/MM/yyyy
          HH:mm:ss' }}
          por {{ company?.nomeUsuarioAceiteTermo }} ({{
            company?.loginUsuarioAceiteTermo
          }})
        </span>
      </label>
      <div
        class="container-info warning text-red-message informacao-certificado">
        <span
          *ngIf="
            company?.idUsuarioAceiteTermo === 0 &&
            !this.loggedUser.indAdministradorEmpresas
          "><br/>
          IMPORTANTE: Apenas o usuário MASTER, pode realizar o aceite do termo.
        </span>
      </div>
    </section>
    <section class="container-footer-modal" footer>
      <button
        pButton
        title="Cancelar"
        (click)="close()"
        class="btn-dark-red"
        type="button"
        label="Cancelar"
        icon="pi pi-times"></button>
    </section>
  </section>
</app-modal>
<app-modal-certificate-contract
  (closeModal)="listenModalContract()"
  [open]="showModalCertificateContract"></app-modal-certificate-contract>
