import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalInfoHomeComponent } from './modal-info-home.component';
import { ModalModule } from '../../../core/components/modal/modal.module';



@NgModule({
  declarations: [ModalInfoHomeComponent],
  imports: [
    CommonModule,
    ModalModule
  ],
  exports: [ModalInfoHomeComponent],
})
export class ModalInfoHomeModule { }
